





















import { Component, Vue } from "vue-property-decorator";
import { ElUpload } from "element-ui/types/upload";

const AppProps = Vue.extend({
  props: {
    files: {
      type: Array,
      default() {
        return []
      }
    }
  }
})

@Component({
  name: "FileUploader",
})
export default class extends AppProps {
  _selectedFile: any = null;
  fileList: Array<any> = [];
  resolveUpload: Function | null = null;
  errorUpload: Function | null = null;

  created() {
    this.$watch('files', () => {
      if(this.files) {
        this.fileList = this.files
      } 
    })  
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get selectedFile() {
    return this._selectedFile;
  }

  handleSuccess(res: any, _rawFile: any) {   
    this.resolveUpload!({ fileUrl: res.relativePath });
  }

  handleError(err: any, _rawFile: any) {
    this.errorUpload!(err);
  }

  handleChange(file: any) {
    this._selectedFile = file;
    this.fileList = [file];
    this.$emit('update', this.fileList)
    console.log('change', this._selectedFile, this.fileList);
  }

  handleRemove() {
    this._selectedFile = null;
    this.fileList = [];
    this.$emit('update', this.fileList)
    console.log('remove', this._selectedFile, this.fileList);
  }


  async uploadFile() {
    let uploader = this.$refs.fileUpload as ElUpload;
    return new Promise<void>((resolve, reject) => {
      this.resolveUpload = resolve;
      this.errorUpload = reject;
      if (uploader && this._selectedFile) {
        uploader!.submit();      
      } 
      else {
        this.resolveUpload({ fileUrl: (this.files[0] as any).fileUrl});      
      }
    });
  }
}

